.react-datepicker-popper {
    z-index: 19;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-family: 'minion-pro',sans-serif !important;
    background: #f8f9fa;
    padding: 14px 20px;
    border: none;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.1), 0 8px 8px 0 rgba(0,0,0,.1), 0 16px 16px 0 rgba(0,0,0,.1), 0 32px 32px 0 rgba(0,0,0,.1), 0 64px 64px 0 rgba(0,0,0,.1);
    border-radius: 6px;
}

@media (max-width: 360px) {
    .react-datepicker{
        padding: 5px 10px;
    }
}
@media (max-width: 740px) {
    .react-datepicker-popper {
        margin: 0;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0) !important;
        z-index: 9999999;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0, .3);
    }

    .react-datepicker {
        transform: translate(-50%, 0) !important;
    }

    .react-datepicker__input-container > input {
        font-size: 15px !important;
    }
}

@media (min-width: 740px) {
    .react-datepicker__input-container > input {
        font-size: 20px !important;
    }
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
}

.react-datepicker-popper > div {
    width: 0;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__portal .react-datepicker__day, .react-datepicker__day {
    font-family: 'minion-pro', sans-serif !important;
    font-size: 15px !important;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    margin: 0;
}

.react-datepicker__day:hover {
    background: #495057;
    color: white;
    border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
    background: #495057;
}

.react-datepicker__day--selected {
    background: #495057;
}

.react-datepicker__day--outside-month {
    color: #ced4da;
    pointer-events: none;
    cursor: auto;
}

.react-datepicker-popper .react-datepicker .react-datepicker__day-name , .react-datepicker__portal .react-datepicker .react-datepicker__day-name {
    box-sizing: border-box;
    color: #868e96;
    padding-left: 10px;
    font-family: 'minion-pro', sans-serif !important;
    font-size: 15px !important;
    text-align: center;
    width: 36px;
    height: 36px;
    margin: 0;
}

.react-datepicker__portal .react-datepicker .react-datepicker__month-container .react-datepicker__current-month, .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
    font-weight: normal;
    margin-bottom: 14px;
    font-family: 'minion-pro', sans-serif !important;
    font-size: 15px !important;
    line-height: normal;
    text-transform: capitalize;
}

.react-datepicker__portal .react-datepicker .react-datepicker__month-container .react-datepicker__header, .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    border: none;
    background: transparent;
}

.react-datepicker__portal .react-datepicker .react-datepicker__navigation, .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    top: 22px;
    outline: none;
    background: none;
    line-height: 27px;
    text-align: center;
    cursor: pointer;
    border: 7px solid transparent;
}

.react-datepicker__portal .react-datepicker .react-datepicker__navigation--previous, .react-datepicker-popper .react-datepicker .react-datepicker__navigation--previous {
    left: 35px;
    border-right-color: #212529;
}

.react-datepicker__portal .react-datepicker .react-datepicker__navigation--previous:hover, .react-datepicker-popper .react-datepicker .react-datepicker__navigation--previous:hover {
    border-right-color: #212529;
}

.react-datepicker__portal .react-datepicker .react-datepicker__navigation--next, .react-datepicker-popper .react-datepicker .react-datepicker__navigation--next {
    right: 35px;
    border-left-color: #212529;
}

.react-datepicker__portal .react-datepicker .react-datepicker__navigation--next:hover, .react-datepicker-popper .react-datepicker .react-datepicker__navigation--next:hover {
    border-left-color: #212529;
}

.react-datepicker__day-name {
    visibility: hidden;
}

.react-datepicker__day-name:first-letter {
    visibility: visible;
}

.react-datepicker__input-container > input {
    line-height: 19px;
    position: relative;
    background: transparent;
    color: #212529;
    width: 100%;
    padding: 14px 0 17px 0;
    font-family: 'minion-pro', sans-serif !important;
    outline: none;
    border: none;
    box-sizing: border-box;
    z-index: 5;
}

.react-datepicker__input-container > input::placeholder {
    color: #495057;
}
