@font-face {
  font-family: "minion-pro";
  src: url("https://use.typekit.net/af/5033af/0000000000000000000151d3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/5033af/0000000000000000000151d3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
       format("woff"),
       url("https://use.typekit.net/af/5033af/0000000000000000000151d3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
       format("opentype");
  font-display:auto;
  font-style:italic;
  font-weight:400;
}

@font-face {
  font-family:"minion-pro";
  src: url("https://use.typekit.net/af/1286c7/0000000000000000000151d6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/1286c7/0000000000000000000151d6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff"),
       url("https://use.typekit.net/af/1286c7/0000000000000000000151d6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"apotek-comp";
  src: url("https://use.typekit.net/af/dc346f/00000000000000007735e03c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/dc346f/00000000000000007735e03c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff"),
       url("https://use.typekit.net/af/dc346f/00000000000000007735e03c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"apotek-comp";
  src: url("https://use.typekit.net/af/8a94d8/00000000000000007735e026/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/8a94d8/00000000000000007735e026/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("woff"),
       url("https://use.typekit.net/af/8a94d8/00000000000000007735e026/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
}

@font-face {
  font-family:"apotek";
  src: url("https://use.typekit.net/af/447532/00000000000000007735e024/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/447532/00000000000000007735e024/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("woff"),
       url("https://use.typekit.net/af/447532/00000000000000007735e024/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
       format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"apotek";
  src: url("https://use.typekit.net/af/e6d048/00000000000000007735e032/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("woff2"),
       url("https://use.typekit.net/af/e6d048/00000000000000007735e032/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("woff"),
       url("https://use.typekit.net/af/e6d048/00000000000000007735e032/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
       format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
}
